import React from "react"
import Helmet from "react-helmet"
import image from "../../images/metaimage.png"

const AppHelmet = () => {
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta name="description" content="Przestrzeń wzrostu - Przemek Gardynik, coaching" />
      <meta
        property="og:description"
        content="Przestrzeń wzrostu - Przemek Gardynik, coaching"
      />
      <meta name="keywords" content="Coaching, Coach, Przemek, Gardynik, Wzrost"/>
      <title>Przestrzeń Wzrostu - Przemek Gardynik</title>
      <meta property="og:title" content="Przestrzeń Wzrostu - Przemek Gardynik" />
      <html lang="en" />
      {/*/!* Google / Search Engine Meta Tags *!/*/}
      <meta itemProp="name" content="Przemek Gardynik" />
      <meta itemProp="image" content={image} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

export default AppHelmet
