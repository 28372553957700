import React from "react"
import Contact from "./Contact"
import BackgroundSection from "../RightColumn/BackgroundImage"

const LeftColumn = () => {
  return (
    <div className="column is-4 has-text-white left-column">
      <div className="has-text-weight-semibold is-size-2 is-size-3-tablet is-flex is-flex-direction-column has-text-centered surname">
        <div className="surname-top" />
        <div className="surname-left" />
        <div className="surname-right" />
        <div className="surname-bottom" />
        <span>PRZEMEK</span>
        <span>GARDYNIK</span>
      </div>
      <div className="text is-flex is-flex-direction-column has-text-left is-justify-content-space-between">
        Jestem dyplomowanym coachem Uniwersytetu SWPS. Prowadzę coaching
        indywidualny i biznesowy. Pomagam przejść przez zmianę, zrobić pierwszy
        i drugi krok na drodze rozwoju. Towarzyszę, tworząc przestrzeń do
        wzrostu.
      </div>
      <div className="is-hidden-tablet background-image-mobile">
        <BackgroundSection />
      </div>
      <div className="text is-flex is-flex-direction-column has-text-left has-text-centered-mobile is-justify-content-space-between">
        <Contact />
      </div>
    </div>
  )
}

export default LeftColumn
