import React from "react"
import { FaFacebook, FaPhone, FaEnvelope } from "react-icons/fa"

const ContactItem = ({ icon, text , link}) => {
  return (
    <a href={link}>
      <div className="is-flex is-align-items-center contact-item ">
        <div className="is-size-4  is-flex is-align-items-center">{icon}</div>
        &nbsp; &nbsp;
        <div className="is-flex is-align-items-center">{text}</div>
      </div>
    </a>
  )
}

const Contact = () => {
  return (
    <div>
      <h3 className="has-text-weight-semibold is-size-3 is-size-4-tablet">KONTAKT</h3>
      <div className="is-flex is-flex-direction-column ">
        <ContactItem icon={<FaPhone />} text="+48609355395" link="tel:+48609355395"/>
        <ContactItem icon={<FaFacebook />} text="Przestrzeń Wzrostu" link="https://www.facebook.com/PrzestrzenWzrostu" />
        <ContactItem icon={<FaEnvelope />} text="przemek@gardynik.com" link="mailto:przemek@gardynik.com"/>
      </div>
    </div>
  )
}

export default Contact
