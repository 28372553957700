import React from "react";
import BackgroundSection from "./BackgroundImage"

const RightColumn = () => {
  return (
    <div className="column is-8 is-paddingless right-column is-hidden-mobile">
      <div className="right-column-info has-text-white">
        <div className="has-text-weight-bold is-size-5 has-text-centered right-column-info-invite">ZAPRASZAM NA SPOTKANIE</div>
        <br/>
        <div className="has-text-left">Aby dokonać trwałej zmiany często pomocne jest ukierunkowane wsparcie drugiej osoby.</div>
      </div>
      <BackgroundSection/>
    </div>
  )
};

export default RightColumn;