import React from "react"
import "../style/style.scss"
import LeftColumn from "./LeftColumn"
import RightColumn from "./RightColumn"
import AppHelmet from "../Helmet"

const App = () => {
  return (
    <>
      <AppHelmet/>
      <div className="has-background-primary is-fullheight">
        <div className="columns">
          <LeftColumn />
          <RightColumn />
        </div>
      </div>
    </>
  )
}

export default App
