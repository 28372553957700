import React from "react";
import {graphql, StaticQuery} from "gatsby";
import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "photo.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="div"
          fluid={imageData}
          className="background-image"
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export default BackgroundSection;